import React from 'react';

const Values = () => {
  return (
    <div>
      <div className="container px-5 py-8 mx-auto">
        <div className="flex flex-wrap -m-4">
          <div className="xl:w-1/3  md:w-1/2 p-4">
            <div className="border border-gray-200 bg-green-600 p-6 rounded-lg">
              {/* <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div> */}
              <h2 className="text-lg text-gray-100 font-bold title-font mb-2">
                WHO WE ARE?
              </h2>
              <p className="leading-relaxed text-white text-base">
                Founded in 1960, we are a non-profit organization that has been
                at the forefront of community development and social change. We
                are committed to making a difference and building a brighter
                future for all. Our mission is to help people help themselves
                and build a better future for all. We believe that everyone has
                the right to a better life and we are committed to making a
                difference and building a brighter future for all. We offer a
                range of programs and services to help people help themselves
                and build a better future for all.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 h-full md:w-1/2 p-4">
            <div className="border border-gray-200 bg-red-700 p-6 rounded-lg">
              {/* <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <circle cx="6" cy="6" r="3"></circle>
                  <circle cx="6" cy="18" r="3"></circle>
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                </svg>
              </div> */}
              <h2 className="text-lg text-gray-100 font-bold title-font mb-2">
                WAGUSE INITIATIVE
              </h2>
              <p className="leading-relaxed text-white text-base">
                WAGUSE is an initiative dedicated to helping those in need. we
                are committed to making a difference in the world by providing
                support and resources to those who need it most. our mission is
                to empower vulnerable communities to improve their lives through
                social and economic development. we offer holistic support
                programs for children and families, as well as community
                development initiatives. we believe that everyone deserves the
                opportunity to live a healthy, happy life, and we are dedicated
                to making that a reality for all.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 h-full md:w-1/2 p-4">
            <div className="border border-gray-200 bg-blue-900 p-6 rounded-lg">
              {/* <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div> */}
              <h2 className="text-lg text-gray-100 font-bold title-font mb-2">
                CHURCH MISSION
              </h2>
              <p className="leading-relaxed text-white text-base">
                Our church is dedicated to serving the community and making a
                difference in the world. We believe in the power of faith and
                love to transform lives and build a better future for all. Our
                mission is to provide support and resources to those in need and
                create positive change in the world. We offer a range of
                programs and services to help people help themselves and build a
                brighter future for all. We believe that everyone has the right
                to a better life and we are committed to making a difference and
                building a brighter future for all.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
