import React from 'react';
import { Cursor, useTypewriter } from 'react-simple-typewriter';

const SectionHero = ({ title }) => {
  const [text, count] = useTypewriter({
    words: ['Give ', 'Support', 'Empower'],
    loop: true,
    delaySpeed: 2000,
  });
  return (
    <div className="h-[30dvh]   lg:h-[60dvh] flex items-center justify-center relative overflow-hidden">
      <img src="images/lagos.jpg" className="object-fill" alt="" />
      <div className=" md:w-full absolute h-full bg-black/50  grid place-items-center">
        {/* <p className="mx-auto mt-5 mb-12 text-center italic text-white max-w-prose text-xs md:text-xl ">
          Remember this: Whoever sows sparingly will also reap sparingly, and
          whoever sows generously will also reap generously. Each of you should
          give what you have decided in your heart to give, not reluctantly or
          under compulsion, for God loves a cheerful giver.
        </p> */}
        <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="hidden md:inline-block w-8 h-8 text-white mb-8"
            viewBox="0 0 975.036 975.036"
          >
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <p className="leading-relaxed text-sm w-4/5 md:w-2/3 mx-auto mt-5 md:mb-12 italic text-white md:text-xl">
            Remember this: Whoever sows sparingly will also reap sparingly, and
            whoever sows generously will also reap generously. Each of you
            should give what you have decided in your heart to give, not
            reluctantly or under compulsion, for God loves a cheerful giver.
          </p>
          <span className="hidden md:inline-block h-1 w-10 rounded bg-white  mb-6"></span>
          <h2 className="text-gray-100 font-bold title-font tracking-wider text-xl">
            2 Corithians 9:6-7
          </h2>
          {/* <p className="text-gray-500">Senior Product Designer</p> */}
        </div>

        {/* <h1 className="text-3xl lg:text-7xl uppercase absolute bottom-6 left-10 font-sans font-bold text-gray-100">
          <p className="text-2xl my-4 text-white sm:text-3xl font-bold">
            <span>{text}</span>
            <Cursor cursorColor="#ffff" />
          </p>
        </h1> */}
      </div>
    </div>
  );
};

export default SectionHero;
