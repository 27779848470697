import React, { useEffect, useState } from 'react';
import 'animate.css'; // Ensure animate.css is imported

const images = [
  '/images/carousel.png',
  '/images/carousel2.jpg',
  '/images/carousel_3.jpg',
  '/images/carousel_4.jpg',
];

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 8000); // Slide every 8 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div className="relative w-screen h-[30dvh] lg:h-[90dvh] z-0 overflow-hidden">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 object-top transition-opacity duration-1000 ease-in-out ${
            currentSlide === index ? 'opacity-100' : 'opacity-0'
          }`}
          style={{
            zIndex: currentSlide === index ? 2 : 1, // Ensure current slide is on top
          }}
        >
          <img
            src={image}
            className="w-full h-full  object-top object-fill"
            alt={`Slide ${index + 1}`}
          />
        </div>
      ))}
    </div>
  );
};

export default Carousel;
