import React from 'react';
import SectionHero from '../sections/SectionHero';
import CausesCards from '../sections/CausesCards';
import Footer from '../sections/Footer';
import Values from '../sections/Values';
import Support from '../sections/Support';

const Causes = ({ modal }) => {
  return (
    <div>
      <SectionHero title={'OUR STORY'} />
      <Values />
      {/* <About /> */}
      <CausesCards give={modal} />
      {/* <Partners /> */}
      <Support give={modal} />
      <Footer />
    </div>
  );
};

export default Causes;
