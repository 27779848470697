import axios from 'axios';
import React, { useState } from 'react';
import { stkPushQuery } from '../actions/stkPushQuery';

const ModPayments = () => {
  const [dataFromForm, setDataFromForm] = useState({
    name: 'Mugambi',
    phoneNumber: '0705469866',
    amount: 1,
    place: 'Nairobi',
    email: 'jeffkaris001@gmail.com',
  });

  const [mpesaType, setMpesaType] = useState('stk');
  const [stkQueryLoading, setStkQueryLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMode, setPaymentMode] = useState('paypal');
  // const [paymentMode, setPaymentMode] = useState('pause');

  const [errorMessage, setErrorMessage] = useState('');

  const handlePesapalSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // const payload = {
    //   name: e.target[0].value,
    //   phoneNumber: e.target[1].value,
    //   amount: e.target[2].value,
    //   email: e.target[3].value,
    //   service: 'Waguse',
    // };

    const raw = {
      // name: e.target[0].value,
      // phoneNumber: e.target[1].value,
      currency: e.target[0].value,
      amount: e.target[1].value,
      // email: e.target[3].value,
      service: 'Waguse',
    };

    console.log(raw);

    axios
      .post('https://verx-4d9b.onrender.com/DPO', raw)
      .then((res) => {
        setLoading(false);
        //open a new window with the url from the response data
        window.open(res.data?.transactionToken, '_blank');
        // Optional: handle payment confirmation logic here
        // setTimeout(() => {
        //   window.location.reload();
        //   alert('You took too long to pay');
        // }, 5000);
      })
      .catch((error) => {
        console.error('Error sending payment request:', error);
        alert('Failed to send payment request');
      });

    //send payload as a body to the backend
    // axios
    //   .post('https://verx-4d9b.onrender.com/DPO', requestOptions)
    //   .then((res) => {
    //     setLoading(false);
    //     alert('Payment request sent');
    //     // Optional: handle payment confirmation logic here
    //     // setTimeout(() => {
    //     //   window.location.reload();
    //     //   alert('You took too long to pay');
    //     // }, 5000);
    //   })
    //   .catch((error) => {
    //     console.error('Error sending payment request:', error);
    //     alert('Failed to send payment request');
    //   });
  };

  //add this just before the handleSubmit Function
  var reqcount = 0;

  const stkPushQueryWithIntervals = (CheckoutRequestID) => {
    const timer = setInterval(async () => {
      reqcount += 1;

      if (reqcount === 15) {
        //handle long payment
        clearInterval(timer);
        setStkQueryLoading(false);
        setLoading(false);
        setErrorMessage('You took too long to pay');
      }

      const { data, error } = await stkPushQuery(CheckoutRequestID);

      if (error) {
        if (error.response.data.errorCode !== '500.001.1001') {
          setStkQueryLoading(false);
          setLoading(false);
          setErrorMessage(error?.response?.data?.errorMessage);
        }
      }

      if (data) {
        if (data.ResultCode === '0') {
          clearInterval(timer);
          setStkQueryLoading(false);
          setLoading(false);
          setSuccess(true);
        } else {
          clearInterval(timer);
          setStkQueryLoading(false);
          setLoading(false);
          setErrorMessage(data?.ResultDesc);
        }
      }
    }, 2000);
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log(dataFromForm);
    const formData = {
      phoneNumber: dataFromForm.mpesa_phone.trim(),
      name: dataFromForm.name.trim(),
      amount: dataFromForm.amount,
    };

    const kenyanPhoneNumberRegex =
      /^(07\d{8}|01\d{8}|2547\d{8}|2541\d{8}|\+2547\d{8}|\+2541\d{8})$/;

    if (!kenyanPhoneNumberRegex.test(formData.phoneNumber)) {
      setLoading(false);

      return alert('Invalid mpesa number');
    }

    if (formData.name.length < 3) {
      setLoading(false);
      return alert('Name must be atleast 3 characters');
    }

    if (formData.amount < 1) {
      setLoading(false);
      return alert('Amount must be atleast 1');
    }

    const cleanedNumber = formData.phoneNumber.replace(/\D/g, '');

    const formattedPhone = `254${cleanedNumber.slice(-9)}`;
    const payload = {
      phoneNumber: formattedPhone,
      amount: formData.amount,
      name: formData.name,
    };

    console.log(JSON.stringify(payload));

    await axios
      .post('https://verx-4d9b.onrender.com/stk', payload)
      .then((res) => {
        // alert('Payment request sent');
        // // Optional: handle payment confirmation logic here
        // setTimeout(() => {
        //   window.location.reload();
        //   alert('You took too long to pay');
        // }, 5000);
      })
      .catch((error) => {
        console.error('Error sending payment request:', error);
        alert('Failed to send payment request');
      });

    // const { data: stkData, error: stkError } = await sendStkPush(formData);

    // if (stkError) {
    //   setLoading(false);
    //   return alert(stkError);
    // }

    // const checkoutRequestId = stkData.CheckoutRequestID;

    // setStkQueryLoading(true);
    // stkPushQueryWithIntervals(checkoutRequestId);
  };
  return (
    <div>
      <img
        src="images/logo.png"
        alt=""
        className="hidden md:block mx-auto w-[100px]"
      />
      <img
        src="images/logo.png"
        alt=""
        className="md:hidden w-[80px] mx-auto"
      />
      {/* <h1 className="text-center text-black uppercase text-xl">Donations</h1> */}
      <div className="">
        {/* <div className="flex md:flex-row flex-col-reverse"> */}
        <div className="w-full md:pt-6 text-center">
          <p className="text-xs  my-4">This transaction will be credited to:</p>
          <p className="font-bold italic">
            LAVINGTON UNITED CHURCH - ENDOWMENT FUND
          </p>
          <p className="">
            Account Number: <span className="font-bold">1102500123</span>
          </p>
          <p className="">
            Bank: <span className="font-bold">NCBA BANK KENYA PLC</span>
          </p>
          {/* <p className="text-xs italic mt-4">
            You can also use this information for{' '}
            <span>RTGS, EFT & PESALINK</span> to donate
          </p> */}
          {/* <p
            onClick={() => {
              setPaymentMode('mpesa');
            }}
            className={`${
              paymentMode === 'mpesa'
                ? 'bg-green-600 font-bold text-white'
                : 'bg-gray-200'
            } w-full text-center flex- mt-2 p-2 cursor-pointer text-2xl rounded-lg`}
          >
            M-PESA
          </p>
          <p
            onClick={() => {
              setPaymentMode('paypal');
            }}
            className={`${
              paymentMode === 'paypal'
                ? ' bg-green-600 font-bold text-white'
                : 'bg-gray-200'
            } w-full text-center mt-2 p-2 cursor-pointer text-2xl rounded-lg`}
          >
            PAYPAL
          </p>
          <p
            onClick={() => {
              setPaymentMode('pesapal');
            }}
            className={`${
              paymentMode === 'pesapal'
                ? 'bg-green-600 font-bold text-white'
                : 'bg-gray-200'
            } w-full text-center p-2 mt-2 cursor-pointer text-2xl rounded-lg`}
          >
            CARD
          </p> */}
        </div>
        <div className="w-full  md:border-b-0 md:ml-2  ">
          {paymentMode === 'pause' && (
            <img src="images/mpesa.png" alt="" className="w-full my-auto" />
          )}
          {paymentMode === 'mpesa' && (
            <div className="md:p-6">
              <div className="flex items-center justify-between">
                <img
                  src="payments/mpesapay.png"
                  alt=""
                  className="w-1/3 my-auto"
                />
                {mpesaType === 'stk' ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMpesaType('manual');
                    }}
                    className="text-sm bg-gray-400 p-2 rounded-md text-white border-2 cursor-pointer border-green-500"
                  >
                    Manual MPESA
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMpesaType('stk');
                    }}
                    className="text-sm bg-gray-400 p-2 rounded-md text-white border-2 cursor-pointer border-green-500"
                  >
                    MPESA Express
                  </button>
                )}
              </div>
              {mpesaType === 'stk' ? (
                <div>
                  <p className="mt-4 text-sm text-gray-600">
                    Provide your name, mpesa number and amount to process
                    donation.
                  </p>
                  <div>
                    <label
                      htmlFor="Name_Mpesa"
                      className=" block text-sm font-medium text-gray-900 "
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      placeholder="Name"
                      name="Name_Mpesa"
                      value={dataFromForm.name}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 mb-2 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500"
                      onChange={(e) =>
                        setDataFromForm({
                          ...dataFromForm,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Mpesa_Phone"
                      className=" block text-sm font-medium text-gray-900 "
                    >
                      Mpesa Phone
                    </label>
                    <input
                      type="text"
                      placeholder="Mpesa Phone"
                      value={dataFromForm.mpesa_phone}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 mb-2 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500"
                      onChange={(e) =>
                        setDataFromForm({
                          ...dataFromForm,
                          mpesa_phone: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Amount"
                      className=" block text-sm font-medium text-gray-900 "
                    >
                      Amount
                    </label>
                    <input
                      type="number"
                      placeholder="Amount"
                      className="block mb-2 w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500"
                      value={dataFromForm.amount}
                      onChange={(e) =>
                        setDataFromForm({
                          ...dataFromForm,
                          amount: e.target.value,
                        })
                      }
                    />
                  </div>

                  <button
                    className="flex w-full items-center justify-center rounded-lg bg-green-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none  "
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? 'Processing...' : 'Donate'}
                  </button>
                </div>
              ) : (
                <div>
                  <p className="mt-4 text-sm text-gray-600">
                    Follow the steps below to complete payment
                  </p>
                  <div className="border border-gray-600 rounded-lg p-4 m-4 bg-amber-500/25 text-gray-800">
                    Go to M-PESA on your phone
                    <ul className="list-disc list-inside">
                      <li>Select Lipa na M-PESA</li>
                      <li>Select Paybill</li>
                      <li>
                        Enter Business Number:{' '}
                        <span className="font-bold">4230038</span>
                      </li>
                      <li>
                        Enter Account Number:{' '}
                        <span className="font-bold">YOUR NAME</span>
                      </li>
                      <li>
                        Enter Amount:{' '}
                        <span className="font-bold">e.g. 100</span>
                      </li>
                      <li>Enter your M-PESA PIN and Send</li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
          {paymentMode === 'paypal' && (
            <div className="mx-auto  md:px-4 ">
              <div className="mx-auto max-w-5xl">
                <div className=" p-4 lg:flex lg:items-start lg:gap-12">
                  <form
                    action="#"
                    onSubmit={handlePesapalSubmit}
                    className="w-full rounded-lg border border-gray-200 bg-white p-4 shadow-sm  lg:max-w-xl lg:p-8"
                  >
                    <div className="mb-6 grid grid-cols-2 gap-4">
                      <div className="col-span-2 ">
                        <label
                          htmlFor="Amount"
                          className=" block text-sm font-medium text-gray-900 "
                        >
                          Amount<span className="text-red-600">*</span>
                        </label>
                        <p className="text-xs italic my-2">
                          &nbsp;Select currency and enter amount to donate
                        </p>
                        <div className="flex flex-row">
                          <select
                            className="block w-1/5 rounded-l-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                            //value={dataFromForm.amount}
                          >
                            <option value="KES">KES</option>
                            <option value="USD">USD</option>
                          </select>
                          <input
                            type="number"
                            placeholder="Amount"
                            className="block w-full rounded-r-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                            //value={dataFromForm.amount}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="flex w-full items-center justify-center rounded-lg bg-red-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none  "
                      disabled={loading}
                    >
                      {loading ? 'Processing...' : 'Donate'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}
          {paymentMode === 'pesapal' && (
            <div className="mx-auto  md:px-4 2xl:px-0">
              <div className="mx-auto max-w-5xl">
                <div className="mt-6 p-4 lg:flex lg:items-start lg:gap-12">
                  <form
                    action="#"
                    onSubmit={handlePesapalSubmit}
                    className="w-full rounded-lg border border-gray-200 bg-white p-4 shadow-sm  lg:max-w-xl lg:p-8"
                  >
                    <div className="mb-6 grid grid-cols-2 gap-4">
                      <div className="col-span-2">
                        <label
                          htmlFor="full_name"
                          className="mb-2 block text-sm font-medium text-gray-900 "
                        >
                          Full name<span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          id="full_name"
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500      "
                          placeholder="John Doe"
                          required
                        />
                      </div>

                      <div className="col-span-2 ">
                        <label
                          htmlFor="phone_number"
                          className="mb-2 block text-sm font-medium text-gray-900 "
                        >
                          Phone Number<span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          id="phone_number"
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pe-10 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500       "
                          placeholder="07xx xxxxxxx"
                          pattern="^(07\d{8}|01\d{8}|2547\d{8}|2541\d{8}|\+2547\d{8}|\+2541\d{8})$"
                          required
                        />
                      </div>
                      <div className="col-span-2 ">
                        <label
                          htmlFor="Amount"
                          className=" block text-sm font-medium text-gray-900 "
                        >
                          Amount<span className="text-red-600">*</span>
                        </label>
                        <input
                          type="number"
                          placeholder="Amount"
                          className="block mb-2 w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500"
                          //value={dataFromForm.amount}
                        />
                      </div>

                      <div className="col-span-2 ">
                        <label
                          htmlFor="email"
                          className="mb-2 block text-sm font-medium text-gray-900 "
                        >
                          Email<span className="text-red-600">*</span>
                        </label>
                        <div className="relative">
                          <input
                            type="email"
                            id="email"
                            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500      "
                            placeholder="xyz@gmail.com"
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="flex w-full items-center justify-center rounded-lg bg-red-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none  "
                      disabled={loading}
                    >
                      {loading ? 'Processing...' : 'Donate'}
                    </button>
                  </form>

                  {/* <div className="mt-6 grow sm:mt-8 lg:mt-0">
                          <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50 p-6  ">
                            <div className="space-y-2">
                              <dl className="flex items-center justify-between gap-4">
                                <dt className="text-base font-normal text-gray-500 ">
                                  Original price
                                </dt>
                                <dd className="text-base font-medium text-gray-900 ">
                                  $6,592.00
                                </dd>
                              </dl>

                              <dl className="flex items-center justify-between gap-4">
                                <dt className="text-base font-normal text-gray-500 ">
                                  Savings
                                </dt>
                                <dd className="text-base font-medium text-green-500">
                                  -$299.00
                                </dd>
                              </dl>

                              <dl className="flex items-center justify-between gap-4">
                                <dt className="text-base font-normal text-gray-500 ">
                                  Store Pickup
                                </dt>
                                <dd className="text-base font-medium text-gray-900 ">
                                  $99
                                </dd>
                              </dl>

                              <dl className="flex items-center justify-between gap-4">
                                <dt className="text-base font-normal text-gray-500 ">
                                  Tax
                                </dt>
                                <dd className="text-base font-medium text-gray-900 ">
                                  $799
                                </dd>
                              </dl>
                            </div>

                            <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
                              <dt className="text-base font-bold text-gray-900 ">
                                Total
                              </dt>
                              <dd className="text-base font-bold text-gray-900 ">
                                $7,191.00
                              </dd>
                            </dl>
                          </div>

                          <div className="mt-6 flex items-center justify-center gap-8">
                            <img
                              className="h-8 w-auto "
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal.svg"
                              alt=""
                            />
                            <img
                              className="hidden h-8 w-auto "
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal-dark.svg"
                              alt=""
                            />
                            <img
                              className="h-8 w-auto "
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa.svg"
                              alt=""
                            />
                            <img
                              className="hidden h-8 w-auto "
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa-dark.svg"
                              alt=""
                            />
                            <img
                              className="h-8 w-auto"
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard.svg"
                              alt=""
                            />
                            <img
                              className="hidden h-8 w-auto "
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard-dark.svg"
                              alt=""
                            />
                          </div>
                        </div> */}
                </div>

                {/* <p className="mt-6 text-center text-gray-500  sm:mt-8 lg:text-left">
                        Payment processed by{' '}
                        <a
                          href="#"
                          title=""
                          className="font-medium text-primary-700 underline hover:no-underline "
                        >
                          Paddle
                        </a>{' '}
                        for{' '}
                        <a
                          href="#"
                          title=""
                          className="font-medium text-primary-700 underline hover:no-underline "
                        >
                          Flowbite LLC
                        </a>
                        - United States Of America
                      </p> */}
              </div>
            </div>
          )}
          {paymentMode === 'card' && (
            <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
              <div className="mx-auto max-w-5xl">
                <div className="mt-6 p-4 lg:flex lg:items-start lg:gap-12">
                  <form
                    action="#"
                    className="w-full rounded-lg border border-gray-200 bg-white p-4 shadow-sm   sm:p-6 lg:max-w-xl lg:p-8"
                  >
                    <div className="mb-6 grid grid-cols-2 gap-4">
                      <div className="col-span-2">
                        <label
                          htmlFor="full_name"
                          className="mb-2 block text-sm font-medium text-gray-900 "
                        >
                          Full name<span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          id="full_name"
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500      "
                          placeholder="Bonnie Green"
                          required
                        />
                      </div>

                      <div className="col-span-2 ">
                        <label
                          htmlFor="card-number-input"
                          className="mb-2 block text-sm font-medium text-gray-900 "
                        >
                          {' '}
                          Card number*{' '}
                        </label>
                        <input
                          type="text"
                          id="card-number-input"
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pe-10 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500       "
                          placeholder="xxxx-xxxx-xxxx-xxxx"
                          pattern="^4[0-9]{12}(?:[0-9]{3})?$"
                          required
                        />
                      </div>
                      <div className="col-span-2 ">
                        <label
                          htmlFor="Amount"
                          className=" block text-sm font-medium text-gray-900 "
                        >
                          Amount
                        </label>
                        <input
                          type="number"
                          placeholder="Amount"
                          className="block mb-2 w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500"
                          //value={dataFromForm.amount}
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="card-expiration-input"
                          className="mb-2 block text-sm font-medium text-gray-900 "
                        >
                          Card expiration*{' '}
                        </label>
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5">
                            <svg
                              className="h-4 w-4 text-gray-500 "
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                          <input
                            datepicker-format="mm/yy"
                            id="card-expiration-input"
                            type="text"
                            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 ps-9 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 "
                            placeholder="12/23"
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="cvv-input"
                          className="mb-2 flex items-center gap-1 text-sm font-medium text-gray-900 "
                        >
                          CVV*
                          <button
                            data-tooltip-target="cvv-desc"
                            data-tooltip-trigger="hover"
                            className="text-gray-400 hover:text-gray-900  "
                          >
                            <svg
                              className="h-4 w-4"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>
                          <div
                            id="cvv-desc"
                            role="tooltip"
                            className="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 "
                          >
                            The last 3 digits on back of card
                            <div
                              className="tooltip-arrow"
                              data-popper-arrow
                            ></div>
                          </div>
                        </label>
                        <input
                          type="number"
                          id="cvv-input"
                          aria-describedby="helper-text-explanation"
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500      "
                          placeholder="•••"
                          required
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="flex w-full items-center justify-center rounded-lg bg-red-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none  "
                      disabled={loading}
                      onClick={(e) => {
                        e.preventDefault();
                        setLoading(true);
                      }}
                    >
                      {loading ? 'Processing...' : 'Donate'}
                    </button>
                  </form>

                  {/* <div className="mt-6 grow sm:mt-8 lg:mt-0">
                          <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50 p-6  ">
                            <div className="space-y-2">
                              <dl className="flex items-center justify-between gap-4">
                                <dt className="text-base font-normal text-gray-500 ">
                                  Original price
                                </dt>
                                <dd className="text-base font-medium text-gray-900 ">
                                  $6,592.00
                                </dd>
                              </dl>

                              <dl className="flex items-center justify-between gap-4">
                                <dt className="text-base font-normal text-gray-500 ">
                                  Savings
                                </dt>
                                <dd className="text-base font-medium text-green-500">
                                  -$299.00
                                </dd>
                              </dl>

                              <dl className="flex items-center justify-between gap-4">
                                <dt className="text-base font-normal text-gray-500 ">
                                  Store Pickup
                                </dt>
                                <dd className="text-base font-medium text-gray-900 ">
                                  $99
                                </dd>
                              </dl>

                              <dl className="flex items-center justify-between gap-4">
                                <dt className="text-base font-normal text-gray-500 ">
                                  Tax
                                </dt>
                                <dd className="text-base font-medium text-gray-900 ">
                                  $799
                                </dd>
                              </dl>
                            </div>

                            <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
                              <dt className="text-base font-bold text-gray-900 ">
                                Total
                              </dt>
                              <dd className="text-base font-bold text-gray-900 ">
                                $7,191.00
                              </dd>
                            </dl>
                          </div>

                          <div className="mt-6 flex items-center justify-center gap-8">
                            <img
                              className="h-8 w-auto "
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal.svg"
                              alt=""
                            />
                            <img
                              className="hidden h-8 w-auto "
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal-dark.svg"
                              alt=""
                            />
                            <img
                              className="h-8 w-auto "
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa.svg"
                              alt=""
                            />
                            <img
                              className="hidden h-8 w-auto "
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa-dark.svg"
                              alt=""
                            />
                            <img
                              className="h-8 w-auto"
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard.svg"
                              alt=""
                            />
                            <img
                              className="hidden h-8 w-auto "
                              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard-dark.svg"
                              alt=""
                            />
                          </div>
                        </div> */}
                </div>

                {/* <p className="mt-6 text-center text-gray-500  sm:mt-8 lg:text-left">
                        Payment processed by{' '}
                        <a
                          href="#"
                          title=""
                          className="font-medium text-primary-700 underline hover:no-underline "
                        >
                          Paddle
                        </a>{' '}
                        for{' '}
                        <a
                          href="#"
                          title=""
                          className="font-medium text-primary-700 underline hover:no-underline "
                        >
                          Flowbite LLC
                        </a>
                        - United States Of America
                      </p> */}
              </div>
            </div>
          )}
          <p className="text-xs text-center italic mt-4">
            <span className="font-bold">Note:</span>You can also use this
            information for <span>RTGS, EFT & PESALINK</span> to donate
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModPayments;
