import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Cards = ({ give }) => {
  const [stats, setStats] = useState({
    peopleHelped: 0,
    givers: 0,
    collectionToDate: 0,
  });

  const data = localStorage.getItem('stats');
  return (
    <section className="text-gray-600 absolute w-full -mt-6 py-4 body-font overflow-hidden">
      <div className="mx-auto">
        <div className="flex">
          <div className="xl:w-1/3 md:w-1/2 bg-green-600 w-full">
            <div className="h-full p-2 md:p-6 rounded-lg text-center relative overflow-hidden">
              {/* <h2 className="text-xs md:text-sm text-gray-100 lg:tracking-widest  mb-1 ">
                THANK YOU FOR THE{' '}
                <span className="underline title-font font-medium">
                  RESULTS
                </span>
              </h2>
              <h2 className="text-xs md:text-sm text-gray-100 tracking-widest  mb-1 ">
                ACHIEVED WITH YOU
              </h2> */}
              {/* <h2 className="text-xs  md:text-2xl text-center text-gray-200 tracking-wide title-font mb-1 font-bold">
                GIVE NOW
              </h2>
              <div className="mb-2 h-1 w-6 mx-auto bg-white text-center"></div> */}

              <div className="">
                <div>
                  <h4 className="text-gray-300 text-xs md:text-sm">PAYBILL</h4>
                  <h1 className="text-xl md:text-5xl text-gray-100 font-bold leading-none">
                    4230048
                  </h1>
                </div>
                <div className="mt-2">
                  <h4 className="text-gray-300 text-xs md:text-sm">
                    ACCOUNT NUMBER
                  </h4>
                  <h1 className="text-xl md:text-5xl text-gray-100 font-bold leading-none">
                    YOUR NAME
                  </h1>
                </div>
              </div>
              {/* <div className="flex justify-evenly">
                <div>
                  <h1 className="text-xl md:text-5xl text-gray-100 font-bold leading-none">
                    0
                  </h1>
                  <h4 className="text-gray-300 text-xs md:text-sm">
                    PEOPLE HELPED
                  </h4>
                </div>
                <div>
                  <h1 className="text-xl md:text-5xl text-gray-100 font-bold leading-none">
                    
                  </h1>
                  <h4 className="text-gray-300 text-xs md:text-sm">GIVERS</h4>
                </div>
              </div> */}
            </div>
          </div>
          <div className=" xl:w-1/3 md:w-1/2 -my-2 bg-amber-500 w-full">
            <div className="h-full p-2 md:p-6 rounded-lg  flex flex-col relative overflow-hidden">
              <h2 className="text-xs md:text-2xl text-center text-gray-200 tracking-wide title-font mb-1 font-bold">
                OUR TARGET
              </h2>
              <h1 className="text-md md:text-4xl text-center text-gray-100 font-bold leading-none">
                KES. 200 MILLION
              </h1>
              {/* <h1 className="text-xs text-center mt-4 md:text-xl text-gray-100 font-bold leading-none">
                (Approx. $1,600,000)
              </h1> */}

              <div className="mt-4  mx-auto  gap-4 text-center">
                <h4 className="text-gray-100 text-xs md:text-sm">
                  COLLECTION TO DATE:
                </h4>
                <h1 className="text-lg md:text-xl text-gray-100 font-bold leading-none">
                  KES. 163,000
                </h1>
              </div>

              {/* <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200"></h1>
              <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200"></h1>
              <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200"></h1> */}
            </div>
          </div>
          <div className=" xl:w-1/3 md:w-1/2 bg-red-800 w-full">
            <div className="h-full p-2 md:p-6 text-center rounded-lg items-center relative overflow-hidden">
              <h4 className="text-xs text-gray-300">HELP US REACH</h4>
              <h2 className="text-md md:text-2xl tracking-wide text-gray-200 title-font md:mb-4 font-bold">
                NEXT GOAL
              </h2>
              <div className="flex justify-center">
                <button
                  onClick={() => give(true)}
                  className="p-2 text-sm bg-amber-600 tracking-widest border hover:text-red-800  hover:bg-gray-200 text-gray-100 rounded-full border-gray-200"
                >
                  GIVE NOW
                </button>
              </div>
              {/* <div className="flex justify-center">
                <button
                  onClick={() => {}}
                  className="p-2 text-sm flex items-center gap-2 tracking-widest border hover:text-red-800  hover:bg-gray-200 text-gray-100 rounded-full border-gray-200"
                >
                  <img src={share} alt="paypal" className="w-4" />
                  SHARE
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cards;
