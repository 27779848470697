import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Our Story', href: '/causes' },
  // { name: 'Events', href: '/about' },
  { name: 'Contact Us', href: '/contacts' },
];

const Navbar = ({ modal }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      const header = document.querySelector('header');
      header?.classList.toggle('sticky', window.scrollY > 0);
    });
  }, []);

  return (
    <header className="bg-black/90">
      <nav
        aria-label="Global"
        className="flex items-center max-w-full justify-between px-4 py-2 lg:px-24"
      >
        <div className="hidden md:block flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            {/* <span className="sr-only">Your Company</span> */}
            <img alt="" src="images/waguse.png" className="h-16 w-auto" />
          </a>
        </div>
        <div className="flex md:hidden ">
          <a href="/" className="-m-1.5 p-1.5">
            {/* <span className="sr-only">Your Company</span> */}
            <img alt="" src="images/logo.png" className="h-8" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-md uppercase font-semibold leading-6 text-gray-100"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div
          className="hidden  lg:flex lg:flex-1 flex-col place-items-end"
          onClick={() => {
            modal(true);
          }}
        >
          <div className="w-2/5 flex justify-center">
            <button className="text-sm border border-red-800 px-4 py-2 rounded-full bg-red-800 font-semibold leading-6 text-gray-100">
              GIVE NOW
            </button>
          </div>

          <div className="w-3/5 grid grid-cols-6 place-items-center gap-4 p-4">
            <div></div>
            <div></div>
            <img src="payments/mpesa.png" alt="paypal" className="w-full" />
            <img src="payments/paypal.png" alt="mpesa" className="w-full" />
            <img src="payments/visa.png" alt="visa" className="w-full" />
            {/* <img src="payments/applepay.png" alt="mpesa" className="w-full" /> */}
            <img src="payments/mastercard.png" alt="visa" className="w-full" />
            {/*<img src="payments/discover.png" alt="visa" className="w-full" /> */}
          </div>
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">WAGUSE</span>
              <img alt="" src="images/logo.png" className="h-8 w-auto" />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <button
                  onClick={() => {
                    setMobileMenuOpen(false);
                    modal(true);
                  }}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  GIVE NOW
                </button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default Navbar;
